import ApexChart from "../../../../Components/Apex-chart";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function FinanceDashboard() {
  const [opacity, setOpacity] = useState("0");
  const [loader, setLoader] = useState("1");

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();

  const {
    data: chartData,
    isLoading: isChartLoading,
    error: chartError,
    isError: isChartError,
    isSuccess: isChartSuccess,
  } = useFetchQuery({
    queryKey: "chart",
    token,
    url: `${BASE_URL}chart`,
  });

  const chart = chartData?.status === 200 ? chartData.data : [];

  useEffect(() => {
    if (!isChartLoading && chartData && isChartSuccess) {
      setLoader("0");
      setOpacity("1");
    } else {
      setLoader("1");
      setOpacity("0");
    }
  }, [isChartLoading, chartData]);

  return (
    <>
      <div
        className="finance-dashboard projects_log"
        style={{ opacity: opacity }}
      >
        <div className="page-title">
          <h1>Finance</h1>
          <p>Here you may track financial information</p>
        </div>
        <div className="chart-wrapper">
          {!!chart.length ? (
            <ApexChart chart={chart} />
          ) : (
            <div>
              <ApexChart chart={chart} />
            </div>
          )}
        </div>
      </div>
      <div className="loader" style={{ opacity: loader }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 44,
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
}

export default FinanceDashboard;
