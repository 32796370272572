import React, { useState, useEffect} from "react";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  useDroppable,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import CustomModal from "../canban/custom-modal";
import {
  PlusOutlined,
  ExclamationCircleFilled,

} from "@ant-design/icons";
import SalesPerformanceChart from "../../../../Components/Apex-chart/salesPerformanceChart";
import { CSS } from "@dnd-kit/utilities";

import Icon from "../../../../Components/Icon";
import KanbanModal from "./KanbanModal";
import useUniversalFetch from "../../../../Hooks/useApi";
import { BASE_URL } from "../../../../Consts/variables";
import { DatePicker, notification, Popover, Modal } from 'antd';
import moment from "moment";


const DraggableCard = ({ item, showEditModal, showDetailModal, showDeleteConfirm, color }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    borderColor:color,
    boxShadow: `0px 3px 9px 0px ${color}24`
  };

  // console.log(item);


  return (
    <>
      
      <li
   
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="canban_column_item"

       
      >
        <div className="item_wrapper">
        <div
            className="action"
          >
            <Icon
              onMouseDown={(e) => {
                e.stopPropagation();
                showEditModal(item);
           

              }}
              className="edit icon"
              icon="edit"
            />
            <Icon
              onMouseDown={(e) => {
                e.stopPropagation();
                showDeleteConfirm(item);
              }}
              className="trash icon"
              icon="trash"
            />
          </div>
          <div className="info_raw">
            <span>Project:</span>
            <strong onMouseDown={()=> {
  showDetailModal(item)
}}  style={{ color: color }}>{item.project_name}</strong>
          </div>
          <div className="info_raw">
            <span>Created on:</span>
            <p>{ moment(+item.date).format('DD.MM.YYYY HH:mm')}</p>
          </div>
          <div className="info_raw">
            <span>Cost:</span>
            <p> {item.cost
              ? Number(item.cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "0"} {item.currency.name}</p>
          </div>
          <div className="info_raw">
            <span>Sales agent:</span>
            <p>{item.sales_agent?.fullname}</p>
          </div>
        </div>
      </li>
     
    </>
  );
};

const Column = ({ column, items, showCreateModal, showEditModal, showDetailModal, showDeleteConfirm}) => {

  const { setNodeRef } = useDroppable({ id: column.name });
  return (
    <div className="canban_list_item" ref={setNodeRef}>
      <div className="column_info">
        <div className="column_name">
          <h3 style={{color:column.color}}>{column.name}</h3>
          <div className="status">
          <Popover
            placement="bottom"
            content={
              <div>
                {column.total && column.total?.map((ek, index)=> (
            <p key={index}>  {ek?.total_cost
              ? Number(ek?.total_cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")+" "+ ek?.currency
              : "0"}</p>
                ))

                }
              </div>
            }
          >
       <span style={{display:"flex", alignItems:"center", padding:"5px"}}>
      Overall Cost
            <Icon icon="arrow-down" />
       </span>
          </Popover>
        </div>
        </div>
        <button onClick={()=> {
          showCreateModal(column.id)
     ;
        }} className="add_card_btn">
          New card <PlusOutlined />
        </button>
      </div>
      <SortableContext
        id={column.name}
        items={items.map((item) => item.id)}
        strategy={rectSortingStrategy}
      >
        <ul className="canban_column" >
          {items.map((item) => (
            <DraggableCard key={item.id} item={item} color={column.color} showDeleteConfirm={showDeleteConfirm}  showEditModal={(el)=> {
              showEditModal(column, el)}}  showDetailModal={(el)=> {
                showDetailModal(column, el)}} />
          ))}
          {items.length === 0 && (
            <li  className="empty-column-placeholder">Drop here</li>
          )}
        </ul>
      </SortableContext>
    </div>
  );
};

const ProjectInner = () => {
  const { confirm } = Modal;
  const token =
  typeof window !== "undefined" &&
  JSON.parse(localStorage.getItem("authToken"));
  const {
    useFetchQuery,
    useFetchMutation,
    usePatchMutation,
    useDeleteMutation,
  } = useUniversalFetch();

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const buildQueryString = () => {
    const params = new URLSearchParams();

    if (dateFrom) {
      params.append("from", dateFrom);
    }
    if (dateTo) {
      params.append("to", dateTo);
    }

    return `?${params.toString()}`;
  };

  const queryString = buildQueryString();

  const {
    data: canbanData,
    isLoading: isCanbanDataLoading,
    isSuccess: isSuccessCanbanData,
    error: canbanDataError,
    isError: isCanbanDataError,
  } = useFetchQuery({
    queryKey: "canban",
    token,
    url: `${BASE_URL}canban`,
    
  });

  const {
    data: chartData,
    isLoading: isChartLoading,
    error: chartError,
    isError: isChartError,
    isSuccess: isChartSuccess,
  } = useFetchQuery({
    queryKey: "sales-performance",
    token,
    url: `${BASE_URL}canban/sales-performance`,
    id:queryString
  });

  
  const {
    data: canbanCreateData,
    isSuccess: isSuccessCreated,
    mutate: canbanCreate,
    isLoading: iscanbanCreateLoading,
    error: canbanCreateError,
    isError: iscanbanCreateError,
  } = useFetchMutation({
    url: `${BASE_URL}canban`,
    method: "POST",
    token: token,
  });

  const {
    data: canbanUpdateData,
    isSuccess: isSuccessUpdated,
    mutate: canbanUpdate,
    isLoading: iscanbanUpdateLoading,
    error: canbanUpdateError,
    isError: iscanbanUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}canban`,
    method: "PATCH",
    token: token,
  });

  const {
    data: DargAndDropUpdateData,
    isSuccess: isSuccessDragAndDropUpdated,
    mutate: DargAndDropUpdate,
    isLoading: isDargAndDropUpdateLoading,
    error: DargAndDropUpdateError,
    isError: isDargAndDropUpdateError,
  } = usePatchMutation({
    url: `${BASE_URL}canban`,
    method: "PATCH",
    token: token,
  });

  const {
    data: canbanDeleteData,
    isSuccess: isSuccessDelete,
    mutate: canbanDelete,
    isLoading: iscanbanDeleteLoading,
    error: canbanDeleteError,
    isError: iscanbanDeleteError,
  } = useDeleteMutation({
    url: `${BASE_URL}canban`,
    method: "DELETE",
    token: token,
  });


  useEffect(() => {
    if (isSuccessDelete) {
      notification.success({
        message: canbanDeleteData?.message,
      });
    } else if (iscanbanDeleteError) {
      notification.error({
        message: "Data not deleted",
        description: canbanDeleteData?.message,
      });
    }
  }, [iscanbanDeleteError, isSuccessDelete, canbanDeleteData, canbanDeleteError]);



  useEffect(()=> {

if (isSuccessCreated) {
  handleCancel()

  notification.success({
    message:canbanCreateData?.message
  })
}

  },[isSuccessCreated, canbanCreateData])

  useEffect(()=> {

   if(isSuccessUpdated) {
    handleCancel()
      notification.success({
        message:canbanUpdateData?.message
      })
    }
    
      },[isSuccessUpdated, canbanUpdateData])
    
      useEffect(()=> {

        if (iscanbanUpdateError) {
           notification.error({
             message:canbanUpdateError?.message
           })
         } else if(iscanbanCreateError){
           notification.error({
             message:canbanUpdateError?.message
           }) 
         }
         
           },[ canbanUpdateError, canbanUpdateError, iscanbanUpdateError])
         

  const chart = chartData?.status === 200 ? chartData.data : [];



  const [boardItems, setBoardItems] = useState([]);
  const [isDraged, setIsDraged] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null);

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);




useEffect(()=> {
if (isSuccessCanbanData ) {
  setBoardItems(canbanData?.status === 200 ? canbanData?.data : [])
  handleCancel()
}
},[canbanData, isSuccessCanbanData])

  const handleSubmit = (expenseData, isEdit, other) => {
if (isEdit && expenseData) {
console.log(other);
  canbanUpdate({
    id:other?.expense?.id,
      data:{
        "project_name": expenseData?.project_name,
        "phone_number":expenseData?.phone_number,
        "client_name": expenseData?.client_name,
        "date": String(expenseData.date
          ? new Date(expenseData.date).getTime()
          : expenseData.date),
          "cost":Number(expenseData.cost.replace(/[^\d]/g, "")),
          "description": expenseData?.description,
          "currency": {"id": expenseData?.currency},
          "sales_agent": {"id": expenseData?.sales_agent},
          canban_status:{id:other?.canban_status}
        }
      }
      )
}
else {
  
  canbanCreate({
    "project_name": expenseData?.project_name,
    "phone_number":expenseData?.phone_number,
    "client_name": expenseData?.client_name,
    "date": String(expenseData.date
    ? new Date(expenseData.date).getTime()
    : expenseData.date),
    "cost":Number(expenseData.cost.replace(/[^\d]/g, "")),
    "description": expenseData?.description,
    "currency": {"id": expenseData?.currency},
    "sales_agent": {"id": expenseData?.sales_agent},
    canban_status:{id:other?.canban_status}
  });
}

  };

  const handleDateChange = (date, dateString) => {
    if (date && date.length === 2) {
      const startDateInMilliseconds = date[0] ? date[0].valueOf() : null;
      const endDateInMilliseconds = date[1] ? date[1].valueOf() : null;
      setDateFrom(startDateInMilliseconds);
      setDateTo(endDateInMilliseconds);
      console.log(startDateInMilliseconds);
      console.log(endDateInMilliseconds);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentCard(null);
  };
  const showEditModal = (column, expense) => {
    setCurrentCard(expense);
    setIsEditMode(true);
    setIsModalVisible(column?.id);

  };

  const showCreateModal = (id) => {
    setCurrentCard(null);
    setIsEditMode(false);
    setIsModalVisible(id);
  };


    const handleOpenConfirmModal = (canban, record) => {
    setConfirmModalVisible(true);
    setCurrentMessage({canban:canban, data:record});
  };

  const handleCloseModalConfirm = () => {
    setConfirmModalVisible(false);
  };

  const showDeleteConfirm = (project) => {
    confirm({
      title: "Are you sure delete this list ?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted Project cannot be recovered",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      className: "delete-pop-up",
      onOk() {
        canbanDelete({
          id: project.id,
        });
        // setDeleteId(todoId)
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
  };

  

  const handleDragEnd = ({ active, over }) => {
  
    setActiveId(null);
    if (!over) return;

    const activeColumnIndex = boardItems.findIndex((column) =>
      column.canbans.find((item) => item.id === active.id)
    );
    const overColumnIndex = boardItems.findIndex(
      (column) =>
        column.name === over.id ||
        column.canbans.find((item) => item.id === over.id)
    );

    if (activeColumnIndex !== overColumnIndex) {
      const activeItem = boardItems[activeColumnIndex].canbans.find(
        (item) => item.id === active.id
      );

      const newBoardItems = [...boardItems];
      newBoardItems[activeColumnIndex].canbans = newBoardItems[
        activeColumnIndex
      ].canbans.filter((item) => item.id !== active.id);

      if (newBoardItems[overColumnIndex].canbans.length === 0) {
        newBoardItems[overColumnIndex].canbans = [activeItem];
      } else {
        newBoardItems[overColumnIndex].canbans.splice(
          newBoardItems[overColumnIndex].canbans.findIndex(
            (item) => item.id === over.id
          ),
          0,
          activeItem
        );
      }

      setBoardItems(newBoardItems);
    } else {
      const newColumnItems = arrayMove(
        boardItems[activeColumnIndex].canbans,
        boardItems[activeColumnIndex].canbans.findIndex(
          (item) => item.id === active.id
        ),
        boardItems[overColumnIndex].canbans.findIndex(
          (item) => item.id === over.id
        )
      );

      const newBoardItems = [...boardItems];
      newBoardItems[activeColumnIndex].canbans = newColumnItems;
      setBoardItems(newBoardItems);
    }


   setIsDraged(true)
    
  };

  const handleDragCancel = () => setActiveId(null);
useEffect(()=> {
  const dragedItems = boardItems.map((item) => ({status:item.id, canban:item.canbans.map(((el, index)=> ({id:el.id, order:index})))}))
  // setTimeout(() => {
    if (isDraged) {
      DargAndDropUpdate({
        id:"update-canban-order",
    data:{
      "orders":dragedItems
    }
      })
    
    }
  // }, 1000);
},[isDraged])


useEffect(()=> {
if (isSuccessDragAndDropUpdated) {
  setIsDraged(false)
}
},[isSuccessDragAndDropUpdated])
  return (
    <>
    
    <DndContext
   
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <div className="sales">
        <div className="page-title">
          <div className="title-wrapper">
            <h1 className="uppercase">Kanban</h1>
            <p>Here you may track information about sales</p>
          </div>
        </div>
   <div className="sales-performance-box">
<div className="title-wrapper">

        <h2>Sales performance</h2>
        <DatePicker.RangePicker
                  className="date-filter"
                  placeholder={["Start date", "Till now"]}
                  allowEmpty={[false, true]}
                  onChange={handleDateChange}
                />
</div>
<SalesPerformanceChart          chart={chart} />

   </div>
        <div className="canban">
          <div className="canban_list_wrapper">
            <ul className="canban_list">
              {boardItems.map((column) => (
                <Column
                showDeleteConfirm={showDeleteConfirm}
                showDetailModal={handleOpenConfirmModal}
                showEditModal={showEditModal}
                showCreateModal={showCreateModal}
                  key={column.name}
                  column={column}
                  items={column.canbans}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <DragOverlay>
        {activeId ? (
          <div className="dragging-overlay">
            <DraggableCard
              item={boardItems
                ?.flatMap((column) => column.canbans)
                ?.find((item) => item.id === activeId)}
            />
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
    <CustomModal
          visible={confirmModalVisible}
          onCancel={handleCloseModalConfirm}
          // onOk={}
          recalculate={handleCloseModalConfirm}
          footer={null}
          heading={false}
          titleClass={"title min-title"}
        >
          <div className="card_item">
            <div
              className="column_name"
              style={{ color:  currentMessage?.canban?.color, border: `1px solid ${currentMessage?.canban?.color}` }}
            >
              {currentMessage?.canban?.name}
            </div>
            <h2 style={{ color: currentMessage?.canban?.color }}> {currentMessage?.data?.project_name}</h2>
            <span className="date">  { moment(+currentMessage?.data?.date).format('DD.MM.YYYY HH:mm')}</span>
            <span>
              <p>Cost:</p>
      
              {currentMessage?.data?.cost
              ? Number(currentMessage?.data?.cost)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "0"}
            </span>

            <span>
              <p>Client name:</p>  {currentMessage?.data?.client_name}
            </span>

            <span>
              <p> Phone number:</p> <a href={`tel:+${currentMessage?.data?.phone_number}`}>{currentMessage?.data?.phone_number}</a>
            </span>

            <h4>Task Description</h4>
            <p className="description">
            {currentMessage?.data?.description}
            </p>
            <span className="agent">
              Sales agent: <p>          {currentMessage?.data?.sales_agent?.fullname}</p>
            </span>
          </div>
        </CustomModal>

    <KanbanModal
    isSuccess={isSuccessCreated || isSuccessUpdated}
        isLoading={iscanbanUpdateLoading || iscanbanCreateLoading}
        expense={currentCard}
        isVisible={isModalVisible}
        onSave={handleSubmit}
        onCancel={handleCancel}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default ProjectInner;
