import React, { useState, useEffect } from "react";
import { Input, DatePicker, Select, Button, Modal, Form } from "antd";
// import { CheckOutlined } from '@ant-design/icons';
import Icon from "../../../../Components/Icon";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BASE_URL } from "../../../../Consts/variables";
import useUniversalFetch from "../../../../Hooks/useApi";
import moment from "moment";
import dayjs from "dayjs";

const EmployeeModal = ({
  employee,
  isVisible,
  onSave,
  onCancel,
  isEditMode,
  isLoading,
}) => {
  const [form] = Form.useForm();
  // const [loadings, setLoadings] = useState([]);
  // const [value, setValue] = useState('');
  // const [selectedCurrency, setSelectedCurrency] = useState();

  const token =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("authToken"));
  const { useFetchQuery, useFetchMutation, usePatchMutation } =
    useUniversalFetch();
  const {
    data: currencyData,
    isLoading: isCurrencyLoading,
    error: currencyError,
    isError: isCurrencyError,
  } = useFetchQuery({
    queryKey: "currency",
    token,
    url: `${BASE_URL}setting/currency`,
  });

  const currencies = currencyData?.status === 200 ? currencyData.data : null;

  const {
    data: experianceData,
    isLoading: isExperianceLoading,
    error: experianceError,
    isError: isExperianceError,
    isSuccess: isSuccesExperiance,
  } = useFetchQuery({
    queryKey: "experiance",
    token: token,
    url: `${BASE_URL}setting/work-experience`,
  });
  const experiance =
    experianceData?.status === 200 ? experianceData.data : null;

  const {
    data: jobTitleData,
    isLoading: isJobTitleLoading,
    error: jobTitleError,
    isError: isJobTitleError,
  } = useFetchQuery({
    queryKey: "jobTitle",
    token,
    url: `${BASE_URL}setting/job-title`,
  });

  const jobTitles = jobTitleData?.status === 200 ? jobTitleData.data : null;

  const {
    data: cityData,
    isLoading: isCityLoading,
    error: cityError,
    isError: isCityError,
  } = useFetchQuery({
    queryKey: "city",
    token,
    url: `${BASE_URL}setting/region`,
  });

  const city = cityData?.status === 200 ? cityData.data : null;

  const {
    data: companyData,
    isLoading: isCompanyLoading,
    error: companyError,
    isError: isCompanyError,
  } = useFetchQuery({
    queryKey: "company",
    token,
    url: `${BASE_URL}company`,
  });

  const company = companyData?.status === 200 ? companyData.data : null;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,11}$/;

  const telegramRegex = /^@.+$/;
  const phoneNumberRegex = /^\+998\d{9}$/;

  const formatNumber = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleChange = (e) => {
    let val = formatNumber(e.target.value);
    form.setFieldsValue({ salary: val });
  };

  useEffect(() => {
    // Set form values by accessing the nested properties of the employee object
    form.setFieldsValue(
      employee
        ? {
            fullname: employee?.fullname,
            phone_number: employee?.phone_number,
            email: employee?.email,
            telegram: employee?.telegram,
            address: employee?.address,
            role: employee?.role,
            region: employee?.region?.id,
            username: employee?.username,
            date_join: dayjs(
              String(moment(Number(employee?.date_join)).format("DD/MM/YYYY")),
              dateFormat
            ),
            job_title: employee?.job_title?.id,
            work_experience: employee?.work_experience?.id,
            salary: employee?.salary
              ? Number(employee.salary)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              : "",

            company: employee?.company[0].id,
            currency: employee?.currency?.id,
            birth_date: dayjs(
              String(moment(Number(employee?.birth_date)).format("DD/MM/YYYY")),
              dateFormat
            ),
          }
        : {
            fullname: null,
            role: null,
            phone_number: null,
            email: null,
            telegram: null,
            address: null,
            region: null,
            password: null,
            username: null,
            date_join: null,
            job_title: null,
            experience: null,
            salary: null,
            company: null,
            currency: null,
            birth_date: null,
          }
    );
  }, [employee, form, isVisible]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values, isEditMode);
        // onCancel();
      })
      .catch((error) => {
        console.error("Submission failed:", error?.message);
      });
  };

  const dateFormat = "DD/MM/YYYY";

  const modalHeader = (
    <div>
      <h1>{isEditMode ? "Edit Employee" : "Add Employee"}</h1>
      <p>
        {isEditMode
          ? "Modify the details of this employee."
          : "Fill in the details for the new employee."}
      </p>
    </div>
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      destroyOnClose
      width={1287}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <div className="form-wrapper">
          <div className="title">{modalHeader}</div>
          {/* <div className="avatar">
                        <div className="icon-wrapper">
                            <Icon icon="Frame" />
                        </div>
                        <div className="avatar-photo">
                                <div className="img">

                                </div>
                            <Icon icon="user"/>
                        </div>
                    </div> */}
          <div className="input-wrapper">
            <div className="single-input-item">
              <Form.Item
                name="fullname"
                label="Full Name"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                    // validateTrigger: 'onChange'
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Write full name..." />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please fill the input",
                  },
                  {
                    pattern: { phoneNumberRegex },
                    message: "+998 ** *** ** **",
                  },
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <PhoneInput placeholder="Write phone number..." />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Please input address!",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Write street and district..." />
              </Form.Item>
            </div>
            <div className="single-input-item calendar">
              <Form.Item
                name="date_join"
                label="Date of Join"
                rules={[
                  {
                    required: true,
                    message: "Please input date of join!",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  onChange={(dateString) => {
                    form.setFieldsValue({ date_join: dateString });
                  }}
                  defaultValue={
                    employee?.date_join ? moment(employee.date_join) : null
                  }
                />
              </Form.Item>
            </div>
            <div className="single-input-item calendar">
              <Form.Item
                name="birth_date"
                label="Birth Date"
                rules={[
                  {
                    required: true,
                    message: "Please input birth date!",
                  },
                ]}
                validateTrigger={["onChange"]}
              >
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  format={dateFormat}
                  // value={form.getFieldValue("birth_date") }
                  onChange={(dateString) => {
                    form.setFieldsValue({ birth_date: dateString });
                  }}
                  defaultValue={
                    employee && employee?.birth_date
                      ? dayjs(
                          String(
                            moment(Number(employee?.birth_date)).format(
                              "DD/MM/YYYY"
                            )
                          ),
                          dateFormat
                        )
                      : null
                  }
                />

                <Icon type="calendar" />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                    validateTrigger: ["onBlur", "onSubmit"], // Validate email format on blur and on form submission
                  },
                  {
                    required: true,
                    message: "Please input E-mail!",
                    validateTrigger: "onChange", // Validate required field as soon as the user types
                  },
                ]}
              >
                <Input type="email" placeholder="Write email..." />
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="telegram"
                label="Telegram"
                rules={[
                  {
                    required: true,
                    message: "Please input telegram username",
                    validateTrigger: "onChange", // Validate required field as soon as the user types
                  },
                  {
                    pattern: telegramRegex,
                    message: "Username must start with @",
                    validateTrigger: ["onBlur", "onSubmit"], // Validate pattern on blur and on form submission
                  },
                ]}
              >
                <Input placeholder="Write Telegram username..." />
              </Form.Item>
            </div>
            <div className="single-input-item salary">
              <Form.Item
                label="Salary"
                name="salary"
                className="salary-inner"
                rules={[
                  {
                    required: true,
                    message: "Please fill",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input
                  value={form.getFieldValue("salary")}
                  onChange={handleChange}
                  placeholder="Add salary..."
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                className="currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "required",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="UZS"
                  style={{ width: 90 }}
                  loading={isCurrencyLoading}
                >
                  {currencies
                    ?.filter((c) => c.is_active)
                    .map((activeCity) => (
                      <Select.Option key={activeCity.id} value={activeCity.id}>
                        {activeCity.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="job_title"
                label="Job Title"
                rules={[
                  {
                    required: true,
                    message: "Select job title",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="Select title..."
                  loading={isJobTitleLoading}
                >
                  {jobTitles
                    ?.filter((c) => c.is_active)
                    .map((activeTitle) => (
                      <Select.Option
                        key={activeTitle.id}
                        value={activeTitle.id}
                      >
                        {activeTitle.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="region"
                label="Choose City"
                rules={[
                  {
                    required: true,
                    message: "Select region or city",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select city..." loading={isCityLoading}>
                  {city
                    ?.filter((c) => c.is_active)
                    .map((activeRegion) => (
                      <Select.Option
                        key={activeRegion.id}
                        value={activeRegion.id}
                      >
                        {activeRegion.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="company"
                label="Company"
                rules={[
                  {
                    required: true,
                    message: "Select company",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="Select company..."
                  loading={isCompanyLoading}
                >
                  {company
                    ?.filter((c) => c.is_active)
                    .map((activeCompany) => (
                      <Select.Option
                        key={activeCompany.id}
                        value={activeCompany.id}
                      >
                        {activeCompany.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="work_experience"
                label="Work Experience"
                rules={[
                  {
                    required: true,
                    message: "Select Work Experience",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select
                  placeholder="Select title..."
                  loading={isExperianceLoading}
                >
                  {experiance
                    ?.filter((c) => c.is_active)
                    .map((activeExperiance) => (
                      <Select.Option
                        key={activeExperiance.id}
                        value={activeExperiance.id}
                      >
                        {activeExperiance.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Select role...",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Select placeholder="Select role...">
                  <Select.Option value="ceo">CEO</Select.Option>
                  <Select.Option value="pm">PM</Select.Option>
                  <Select.Option value="hr">HR</Select.Option>
                  <Select.Option value="sales">SALES</Select.Option>
                  <Select.Option value="dev">DEV</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="single-input-item">
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: isEditMode ? false : true,
                    message: "Please input username or login",
                  },
                ]}
                validateTrigger={["onBlur", "onSubmit", "onChange"]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </div>
            <div className="single-input-item password">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: isEditMode ? false : true,
                    message: "Please input password!",
                    validateTrigger: "onChange", // This triggers as soon as you start typing
                  },
                  {
                    pattern: passwordRegex,
                    message:
                      "Password must be at least 8 characters long but shorter than 12, and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&).",
                    validateTrigger: "onSubmit", // This triggers only on form submission
                  },
                ]}
              >
                <Input.Password
                  autocomplete="new-password"
                  placeholder="Password"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EmployeeModal;
