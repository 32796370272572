import Icon from "../Icon"
import Logo from "../../Assets/images/logo.svg"
// import Notification from "../../Assets/images/notification.svg"
import Avatar from "../../Assets/images/user.svg"
// import Drop from "../../Assets/images/chevron-down.svg"
// import { useNavigate, useParams } from "react-router-dom"/
// import { useEffect } from "react"
// import { Avatar, Badge, Space } from 'antd';
// import { UserOutlined } from '@ant-design/icons';



function Navbar(){
    const userData = JSON.parse(localStorage.getItem('userData'))

//     const navigate = useNavigate()
//     const {pathname} = useParams()
// console.log(userData);
//     useEffect(()=> {
//         navigate(pathname, { replace: true });
//     }, [])
    return(
        <nav>
            <div className="nav-wrapper">
                <div className="left-menu">
                    <Icon icon="hamburger" />
                    <img src={Logo} alt="Logo" className="logo-black" />
                </div>
                <div className="right-profile">
                    {/* <div className="notifications">
                        <img src={Notification} alt="Logo" />
                    </div> */}
                    <div className="profile">
                        <div className="avatar">
                            <img src={Avatar} alt="Logo" />
                        </div>
                        <div className="info">
                            <h3>{userData?.fullname}</h3>
                            <span>{userData?.role}</span>
                        </div>
                    </div>
                    {/* <div className="drop-down">
                        <img src={Drop} alt="Logo" />
                    </div> */}
                </div>
            </div>
        </nav>
    )
}

export default Navbar