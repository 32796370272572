import { Route, Routes } from "react-router-dom";
import Projects from "../Pages/Projects";
import Calculator from "./Sales/calculator";
import Employees from "./Employees";
import Log from "./Log";
import Single from "./Projects/Single";
import Job from "./Settings/Job";
import Currency from "./Settings/Currency";
import Country from "./Settings/Country";
import ProjectType from "./Settings/ProjectType";
import Login from "../Auth/Login";
import Integration from "./Settings/Integration";
import Experiance from "./Settings/Experiance";
import Expense from "./Settings/ExpenceType";
import Wage from "../Pages/Settings/Wage";
import Dashboard from "../Pages/Finance/Dashboard";
import Expenses from "./Finance/Expenses";
import IncomeType from "./Settings/IncomeType";
import Income from "./Finance/Income";
import Support from "./Finance/Support";
import Canban from "./Sales/canban";
import CanbanType from "./Settings/CanbanType";
function Pages() {
  console.log(process.env.REACT_APP_API_URL);
  return (
    <>
      <div className="pages">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:projectId" element={<Single />} />
          <Route path="/log" element={<Log />} />
          <Route path="/sales/calculator" element={<Calculator />} />
          <Route path="/sales/canban" element={<Canban />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/settings/job-title" element={<Job />} />
          <Route path="/settings/currency" element={<Currency />} />
          <Route path="/settings/country" element={<Country />} />
          <Route path="/settings/project-type" element={<ProjectType />} />
          <Route path="/settings/integration" element={<Integration />} />
          <Route path="/settings/canbantype" element={<CanbanType />} />
          <Route path="/settings/experiance" element={<Experiance />} />
          <Route path="/settings/expense-type" element={<Expense />} />
          <Route path="/settings/income-type" element={<IncomeType />} />
          <Route path="/settings/company-wage" element={<Wage />} />
          <Route path="/finance/dashboard" element={<Dashboard />} />
          <Route path="/finance/expenses" element={<Expenses />} />
          <Route path="/finance/income" element={<Income />} />
          <Route path="/finance/support" element={<Support />} />
        </Routes>
      </div>
    </>
  );
}

export default Pages;
